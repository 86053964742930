import React from 'react';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Alert(props) {

  return (

    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="dialogue-title" >
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" className="dialogue-text" >
          {props.text}
        </DialogContentText>
        {props.linkAddress ? <Link href={props.linkAddress} target="_blank" rel="noopener noreferrer" >{props.linkText}</Link> : ""}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal} className="purple-background" color="#3C1874">
          {props.closeMessage}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

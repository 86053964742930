import React from 'react';
import {Link} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';

// Navigation bar

const Navbar = (props) => {

  let urlEtherscan = ""
  if (props.userAccount && props.userAccount.length > 0) {
    urlEtherscan = 'https://etherscan.io/address/' + props.userAccount
  }

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };
  const links = [
    {
      label: "Home",
      destination: "/"
    },
    {
      label: "Minting a Secret",
      destination: "/mint-a-secret"
    },
    {
      label: "Holdings",
      destination: "/holdings"
    },
    {
      label: "About",
      destination: "/about"
    },
  ]
  const list = () => (
    <div
      className="links-list"
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {links.map(({label, destination}) => (
          <Link className='header-nav-link' to={destination} >
            <ListItem button key={label}>
              {label}
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        {urlEtherscan.length > 0 ?
          <a
            className={urlEtherscan.length > 0 ? 'header-nav-link' : 'header-nav-link disabled'}
            href={urlEtherscan}
            target="_blank"
            alt="See address on Etherscan.io"
            rel="noopener noreferrer"
          >
            <Tooltip title={urlEtherscan.length > 0 ? props.userAccount : "No Etherscan Account"}>
              <ListItem button>
                Etherscan
              </ListItem>
            </Tooltip>
          </a>
        :
          <div
            className='header-nav-link disabled'
            alt="See address on Etherscan.io"
          >
            <Tooltip title="No wallet connected">
              <ListItem button>
                Etherscan
              </ListItem>
            </Tooltip>
          </div>
        }
      </List>
    </div>
  );
  return (
    <nav className="header">
      <Drawer
        open={openDrawer}
        onClose={toggleDrawer(false)}
        anchor="right"
        className="drawer"
      >
        {list()}
      </Drawer>
      <Link
        className="header-title"
        to="/"
      >SECRETS EXCHANGE</Link>
      <div className="header-right" onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </div>

    </nav>
  );
}

export default Navbar;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import _ from "lodash";
import Navbar from "./Navbar";
import About from "./views/About";
import MintSecret from "./views/MintSecret";
import Holdings from "./views/Holdings";
import Home from "./views/Home";
import ViewSecret from "./views/ViewSecret";
import Secret from '../abis/Secret.json';
import "../scss/skeleton.scss";
import Alert from "./Alert";

//statically import the images
// if more images are added, they need to be added here as well in the consecutive order
import image_1 from './images/1.gif';
import image_2 from './images/2.gif';
import image_3 from './images/3.gif';
import image_4 from './images/4.gif';
import image_5 from './images/5.gif';
import image_6 from './images/6.gif';
import image_7 from './images/7.gif';
import image_8 from './images/8.gif';
import image_9 from './images/9.gif';
import image_10 from './images/10.gif';
import image_11 from './images/11.gif';
import image_12 from './images/12.gif';
import image_13 from './images/13.gif';
import image_14 from './images/14.gif';
import image_15 from './images/15.gif';
import image_16 from './images/16.gif';
import image_17 from './images/17.gif';
import image_18 from './images/18.gif';
import image_19 from './images/19.gif';
import image_20 from './images/20.gif';
import image_21 from './images/21.gif';
import image_22 from './images/22.gif';
import image_23 from './images/23.gif';
import image_24 from './images/24.gif';
import image_25 from './images/25.gif';
import image_26 from './images/26.gif';
import image_27 from './images/27.gif';
import image_28 from './images/28.gif';
import image_29 from './images/29.gif';
import image_30 from './images/30.gif';
import image_31 from './images/31.gif';
import image_32 from './images/32.gif';
import image_33 from './images/33.gif';
import image_34 from './images/34.gif';
import image_35 from './images/35.gif';
import image_36 from './images/36.gif';
import image_37 from './images/37.gif';
import image_38 from './images/38.gif';
import image_39 from './images/39.gif';
import image_40 from './images/40.gif';
import image_41 from './images/41.gif';
import image_42 from './images/42.gif';
import image_43 from './images/43.gif';
import image_44 from './images/44.gif';
import image_45 from './images/45.gif';
import image_46 from './images/46.gif';
import image_47 from './images/47.gif';
import image_48 from './images/48.gif';
import image_49 from './images/49.gif';
import image_50 from './images/50.gif';
import image_51 from './images/51.gif';
import image_52 from './images/52.gif';
import image_53 from './images/53.gif';
import image_54 from './images/54.gif';
import image_55 from './images/55.gif';
import image_56 from './images/56.gif';
import image_57 from './images/57.gif';
import image_58 from './images/58.gif';
import image_59 from './images/59.gif';
import image_60 from './images/60.gif';
import image_61 from './images/61.gif';
import image_62 from './images/62.gif';
import image_63 from './images/63.gif';
import image_64 from './images/64.gif';
import image_65 from './images/65.gif';
import image_66 from './images/66.gif';
import image_67 from './images/67.gif';
import image_68 from './images/68.gif';
import image_69 from './images/69.gif';
import image_70 from './images/70.gif';
import image_71 from './images/71.gif';
import image_72 from './images/72.gif';
import image_73 from './images/73.gif';
import image_74 from './images/74.gif';
import image_75 from './images/75.gif';
import image_76 from './images/76.gif';
import image_77 from './images/77.gif';
import image_78 from './images/78.gif';
import image_79 from './images/79.gif';
import image_80 from './images/80.gif';
import image_81 from './images/81.gif';
import image_82 from './images/82.gif';
import image_83 from './images/83.gif';
import image_84 from './images/84.gif';
import image_85 from './images/85.gif';
import image_86 from './images/86.gif';
import image_87 from './images/87.gif';
import image_88 from './images/88.gif';
import image_89 from './images/89.gif';
import image_90 from './images/90.gif';
import image_91 from './images/91.gif';
import image_92 from './images/92.gif';
import image_93 from './images/93.gif';
import image_94 from './images/94.gif';
import image_95 from './images/95.gif';
import image_96 from './images/96.gif';
import image_97 from './images/97.gif';
import image_98 from './images/98.gif';
import image_99 from './images/99.gif';
import image_100 from './images/100.gif';

const axios = require('axios')
const moment = require('moment-timezone')
const baseWebServiceUrl = process.env.REACT_APP_WEBSERVICE_PROTOCOL + '://' + process.env.REACT_APP_WEBSERVICE_IP + ':' + process.env.REACT_APP_WEBSERVICE_PORT
const Web3 = require('web3')

require('dotenv').config()
const web3 = new Web3(process.env.REACT_APP_RPC_URL)
web3.eth.accounts.wallet.add(process.env.REACT_APP_PRIVATE_KEY)

const TOTAL_IMAGES = 100;

var userAccount = "";
var randomSecret = "";
var randomSecretId = "";
var showResults = true;
var showShareMySecretComponent = true;
//choose a random image, assuming images are named by consecutive numbers starting from 1.png
var imageUrl = "";
let mapTokenImages = new Map();
let mapUserImages = new Map();
let mapAllImages = new Map();

// UTILITIES
const formUrlEncoded = x =>
   Object.keys(x).reduce((p, c) => p + `&${c}=${encodeURIComponent(x[c])}`, '')

const now = () => (moment().tz('America/Chicago').format())

//get random integer between 0 and max
//used in the context of getting a random secret and a random picture when sharing a secret
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

// Main class of the application

class App extends Component {

  //loading web3 and the blockchain on mount
  async componentWillMount() {
    // detect Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if (!isFirefox) {
      await this.loadWeb3()
      await this.loadBlockchainData()
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  //
  // Description: Mapping the images with corresponding objects.
  // when adding new images, add also the corresponding
  // lines with the same naming convention
  ///////////////////////////////////////////////////////////////////////////////////////////

  initImages() {

    mapAllImages.set("1.gif", image_1);
    mapAllImages.set("2.gif", image_2);
    mapAllImages.set("3.gif", image_3);
    mapAllImages.set("4.gif", image_4);
    mapAllImages.set("5.gif", image_5);
    mapAllImages.set("6.gif", image_6);
    mapAllImages.set("7.gif", image_7);
    mapAllImages.set("8.gif", image_8);
    mapAllImages.set("9.gif", image_9);
    mapAllImages.set("10.gif", image_10);
    mapAllImages.set("11.gif", image_11);
    mapAllImages.set("12.gif", image_12);
    mapAllImages.set("13.gif", image_13);
    mapAllImages.set("14.gif", image_14);
    mapAllImages.set("15.gif", image_15);
    mapAllImages.set("16.gif", image_16);
    mapAllImages.set("17.gif", image_17);
    mapAllImages.set("18.gif", image_18);
    mapAllImages.set("19.gif", image_19);
    mapAllImages.set("20.gif", image_20);
    mapAllImages.set("21.gif", image_21);
    mapAllImages.set("22.gif", image_22);
    mapAllImages.set("23.gif", image_23);
    mapAllImages.set("24.gif", image_24);
    mapAllImages.set("25.gif", image_25);
    mapAllImages.set("26.gif", image_26);
    mapAllImages.set("27.gif", image_27);
    mapAllImages.set("28.gif", image_28);
    mapAllImages.set("29.gif", image_29);
    mapAllImages.set("30.gif", image_30);
    mapAllImages.set("31.gif", image_31);
    mapAllImages.set("32.gif", image_32);
    mapAllImages.set("33.gif", image_33);
    mapAllImages.set("34.gif", image_34);
    mapAllImages.set("35.gif", image_35);
    mapAllImages.set("36.gif", image_36);
    mapAllImages.set("37.gif", image_37);
    mapAllImages.set("38.gif", image_38);
    mapAllImages.set("39.gif", image_39);
    mapAllImages.set("40.gif", image_40);
    mapAllImages.set("41.gif", image_41);
    mapAllImages.set("42.gif", image_42);
    mapAllImages.set("43.gif", image_43);
    mapAllImages.set("44.gif", image_44);
    mapAllImages.set("45.gif", image_45);
    mapAllImages.set("46.gif", image_46);
    mapAllImages.set("47.gif", image_47);
    mapAllImages.set("48.gif", image_48);
    mapAllImages.set("49.gif", image_49);
    mapAllImages.set("50.gif", image_50);
    mapAllImages.set("51.gif", image_51);
    mapAllImages.set("52.gif", image_52);
    mapAllImages.set("53.gif", image_53);
    mapAllImages.set("54.gif", image_54);
    mapAllImages.set("55.gif", image_55);
    mapAllImages.set("56.gif", image_56);
    mapAllImages.set("57.gif", image_57);
    mapAllImages.set("58.gif", image_58);
    mapAllImages.set("59.gif", image_59);
    mapAllImages.set("60.gif", image_60);
    mapAllImages.set("61.gif", image_61);
    mapAllImages.set("62.gif", image_62);
    mapAllImages.set("63.gif", image_63);
    mapAllImages.set("64.gif", image_64);
    mapAllImages.set("65.gif", image_65);
    mapAllImages.set("66.gif", image_66);
    mapAllImages.set("67.gif", image_67);
    mapAllImages.set("68.gif", image_68);
    mapAllImages.set("69.gif", image_69);
    mapAllImages.set("70.gif", image_70);
    mapAllImages.set("71.gif", image_71);
    mapAllImages.set("72.gif", image_72);
    mapAllImages.set("73.gif", image_73);
    mapAllImages.set("74.gif", image_74);
    mapAllImages.set("75.gif", image_75);
    mapAllImages.set("76.gif", image_76);
    mapAllImages.set("77.gif", image_77);
    mapAllImages.set("78.gif", image_78);
    mapAllImages.set("79.gif", image_79);
    mapAllImages.set("80.gif", image_80);
    mapAllImages.set("81.gif", image_81);
    mapAllImages.set("81.gif", image_82);
    mapAllImages.set("83.gif", image_83);
    mapAllImages.set("84.gif", image_84);
    mapAllImages.set("85.gif", image_85);
    mapAllImages.set("86.gif", image_86);
    mapAllImages.set("87.gif", image_87);
    mapAllImages.set("88.gif", image_88);
    mapAllImages.set("89.gif", image_89);
    mapAllImages.set("90.gif", image_90);
    mapAllImages.set("91.gif", image_91);
    mapAllImages.set("92.gif", image_92);
    mapAllImages.set("93.gif", image_93);
    mapAllImages.set("94.gif", image_94);
    mapAllImages.set("95.gif", image_95);
    mapAllImages.set("96.gif", image_96);
    mapAllImages.set("97.gif", image_97);
    mapAllImages.set("98.gif", image_98);
    mapAllImages.set("99.gif", image_99);
    mapAllImages.set("100.gif", image_100);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  //
  // Description: Loads web3 and checks if the browser supports Metamask
  // If the browser does not have a metamask wallet,
  // redirect the user to the Metamask help page on the site
  ///////////////////////////////////////////////////////////////////////////////////////////
  async getAccount() {
    if (window.ethereum) {

      const accounts = await window.ethereum.enable();
      const account = accounts[0];
      // console.log("NEW ACCOUNT", account, accounts)
      this.setState({ account })
      return account
    }
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      let currentComponent = this;
      window.ethereum.on('accountsChanged', function (accounts) {
        // console.log({accounts})
        currentComponent.getAccount();
      })
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
  }

  componentDidMount() {
    this.getAccount()
  }


///////////////////////////////////////////////////////////////////////////////////////////
//
// Description: check if a specific image exists in the users' current minted images
// Parameters:
//            imageUrl : string representing an image url
///////////////////////////////////////////////////////////////////////////////////////////
checkImageExist(imageUrlParam) {

  var i = 0;
  for (i=0; i < mapUserImages.length;i++) {
   // console.log(mapUserImages.get(i))
      if (imageUrlParam.localeCompare(mapUserImages.get(i)) === 0) {
       // console.log('image in the list of minted ',imageUrl, mapUserImages.get(i))
        return true;
      }
  }
  //console.log('image not in the list of minted', imageUrl)
  return false;
}


///////////////////////////////////////////////////////////////////////////////////////////
// Description:  Get a random image but checks that the image has not been used yet.
//               If it doesn't find, then pick randomaly a new image
//
// Parameters:
//            imageUrl : string representing an image url
///////////////////////////////////////////////////////////////////////////////////////////
  getRandomImage(totalUserMintedSecrets) {
   var foundImagedNotUsedBefore = false;
   var i = 0;
   var myImageUrl = "";

   while (foundImagedNotUsedBefore === false && i < totalUserMintedSecrets) {

      var  randomImageNumber = getRandomInt(TOTAL_IMAGES)+1
      myImageUrl = baseWebServiceUrl + "/images/" + randomImageNumber + '.gif';
      if (this.checkImageExist(myImageUrl) === false) {
        foundImagedNotUsedBefore = true;
      }
      //console.log('found never used image for this user', myImageUrl,   'totalUserMintedSecrets',totalUserMintedSecrets, 'i', i, 'foundImagedNotUsedBefore', foundImagedNotUsedBefore)
      i++;
    }

    //if not found a random image that was not used before, choose a ew random
    if (foundImagedNotUsedBefore === false) {
        //console.log('didn\'t find any free image so choose randomly a new one','i', i, myImageUrl )
      var  randomImageNumber = getRandomInt(TOTAL_IMAGES)+1
        myImageUrl = baseWebServiceUrl + "/images/" + randomImageNumber + '.gif'
        //console.log('new picked image:', imageUrl)
      }

    //console.log('new picked image:', imageUrl)
    return myImageUrl;

  }


///////////////////////////////////////////////////////////////////////////////////////////
// Description:  Loads token information from the database, by calling the web service
// webservice-php-json/get_token_info.php in the backend.
// The URL is provided by the smart contractitself. When a new secret is minted, a URL is kept
// in the blockchain that will be used to retrieve additional information about the token,
// like the image of the token.
//
// Parameters:
//            secretTokenURI : string representing the url that was kept in the blockchain
//            during the minting process (get_token_info.php is the method called)
///////////////////////////////////////////////////////////////////////////////////////////

  async loadSecretImage(secretTokenURI, secret) {
    // let secretText = encodeURIComponent(JSON.stringify(secret));

    var result;

   // console.log('beginning ', process.env.REACT_APP_WEBSERVICE_IP)
//    var res = secretTokenURI.replace("localhost", process.env.REACT_APP_WEBSERVICE_IP);
    const urlreplace = 'https://' + process.env.REACT_APP_WEBSERVICE_IP + ':' + process.env.REACT_APP_WEBSERVICE_PORT
    var res = secretTokenURI.replace("http://localhost:8080", urlreplace ) + '&origin=' + userAccount + '&secret=' + 'test';
   // var res = secretTokenURI.replace("http://localhost:8080", urlreplace ) + '&origin=' + userAccount + '&secret=' + secretText;

    const configaxios = { headers: {
            'Access-control-Allow-Headers' : '  Origin, X-Requested-With, Content-Type, Accept, Authorization'
            }};

    try {
        var response = await axios.get(res)
    }
    catch (error) {

     console.log('the error is ', error)
    }

    // result = "fake_url";
    if (response.data.info.length > 0 && response.data.info[0].image_url) result = response.data.info[0].image_url;
    else result = ""

    return result;
  }


///////////////////////////////////////////////////////////////////////////////////////////
// Description:  Loads all the information from the blockchain. Every time a user mints a secret,
// it is kept in the blockchain. So the role of this function is to load the minted tokens
// if any. Then they will be later displayed for the user.
//
///////////////////////////////////////////////////////////////////////////////////////////

  gifFileFromImageUrl = (imageUrl) => {
    return imageUrl.slice(imageUrl.indexOf("/images") + 8)
  }

  imageUrlFromCDN = (imageUrl) => {
    return imageUrl.slice(imageUrl.indexOf("/images"))
  }

  async loadBlockchainData() {
    const web3 = window.web3

    this.initImages();
    imageUrl = this.getRandomImage(0)
    this.setState({ imageUrl })


    // Load account
    if (typeof(web3) == "undefined") {
      // window.close()
    }


    try {

      userAccount = await this.getAccount();
      // userAccount = this.state.account;

      const userAddress = userAccount
      const networkId = await web3.eth.net.getId()
      const networkData = Secret.networks[networkId]
      if (networkData) {

        const abi = Secret.abi
        const address = networkData.address

        const contract = new web3.eth.Contract(abi, address)
        this.setState({ contract })
        const totalSupply = await contract.methods.totalSupply().call()
        const tokenIds = await this.state.contract.methods.tokensOfOwner(userAddress).call()

        this.setState({ totalSupply })
        const userTotalTokens = tokenIds.length;
        this.setState({userTotalTokens})
        // Load Secrets

        for (var i = 1; i <= tokenIds.length; i++) {
          const secret = await contract.methods.secrets(tokenIds[i-1]-1).call()

          const myUri = await contract.methods.tokenURI(tokenIds[i-1]).call()

          this.setState({
            secrets: [...this.state.secrets, secret]
          })
          const mySecretImgUrl = await this.loadSecretImage(myUri, secret);

          var res = this.gifFileFromImageUrl(mySecretImgUrl) 
          
          mapTokenImages.set(secret, res);   // a string key
          mapUserImages.set(i,res);
          
          this.setState({ myUri })
          showShareMySecretComponent = true;
          this.setState({ showShareMySecretComponent })
          
        }

        imageUrl = this.getRandomImage(tokenIds.length)
        imageUrl = this.imageUrlFromCDN(imageUrl)
        this.setState({ imageUrl })

      } else {
        window.alert('Smart contract not deployed to detected network.')
      }
    }
    catch(error) {
      console.log(error)
    }
  }


///////////////////////////////////////////////////////////////////////////////////////////
// Description:  Operation of minting the selected secret/image directly on the blockchain
// Smart contract is called. The call is asynchronous and the operation can take several
// minutes, so there is no rational in keeping the user waiting in the page. Just mint and wait,
// a Metamask notification will appear when the token is minted.
//
// Parameters:
//           secret: string representing the secret
//           secretId: The ID in the database of the secret record. This will be used later
//           when retrieving the information about the minted tokens for a specific user.
///////////////////////////////////////////////////////////////////////////////////////////

  mint = (secret,secretId) => {
    // console.log(secret)

    if (this.state.account === undefined || this.state.account === null || (this.state.account).localeCompare("") ==0) {
        const myRedirect = true ;
        this.setState({myRedirect})
        // window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
        // window.close()
    }

    const configaxios = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const signupUrl = baseWebServiceUrl + '/webservice-php-json/register_minted_token.php'
    axios.post(signupUrl, formUrlEncoded({
      secret: secret,
      origin: userAccount,
      image_url: imageUrl,
      date_of_creation:now(),
      approved:0,
      secret_id: randomSecretId

    }),configaxios)
    .then((response) => {
     // console.log(response);
    }, (error) => {
    //  console.log(error);
    });

    this.state.contract.methods.mint(secret,secretId).send({ from: this.state.account })
    .once('receipt', (receipt) => {
      this.setState({
        secrets: [...this.state.secrets, secret]
      })
    })

    showShareMySecretComponent = true;
    this.setState({ showShareMySecretComponent })
  }


 ///////////////////////////////////////////////////////////////////////////////////////////
// Description:  Save the secret in the database using the backend web service
//               webservice-php-json/signup.php
//               After saving the secret, we load a new random secret that will be displayed
//               to the user upon clicking on "Share my secret"
//
// Parameters:
//           secret: string representing the secret
///////////////////////////////////////////////////////////////////////////////////////////

async saveSecret (secret, extraFunc, newState, newImageFunc) {

  const loadSecret = async() => {
    // Description:  Loads a secret from the database, where the conditions need to be that the
    // combination secret/image cannot be duplicated.
    // Calls the web service in the back end get_random_secret.php until if finds a
    // secret meeting the conditions

      var result

      const getCountUrl = baseWebServiceUrl + '/webservice-php-json/get_user_tokens_count.php?uid=' + userAccount
      var response = await axios.get(getCountUrl)

      if (response === undefined || response === null) {
        // console.log('empty result when calling axios for random secret')

      } else {
        result = response.data.total;
        randomSecretId = response.data.total;
      }

      var newSecretFound = false
      while (newSecretFound === false) {
      const randomSecretUrl = baseWebServiceUrl + '/webservice-php-json/random_secret.php?uid=1'
      // console.log('randomSecretUrl', randomSecretUrl)
        var response = await axios.get(randomSecretUrl)
        if (response === undefined || response === null) {

        } else {
          result = response.data.info[0].secret;
          randomSecretId = response.data.info[0].id;
        }

        //if non ethereum browser, do not allow to share a secret
        if (newState.account === undefined || newState.account === null || (newState.account).localeCompare("") ==0) {
          // console.log("ACCOUNT ISSUES", _.get(response, "data.info[0].image_url", "https://secrets.exchange:4443/images/1.gif"))
          // IF NOT LOGGED INTO METAMASK - SHOW DEFAULT (GIF #1)
          const imageIndex = Math.floor(Math.random() * 100) + 1;
          newImageFunc(`https://secrets.exchange:4443/images/${imageIndex}.gif`)
           //not supported browser so no need to check if secret exists
           newSecretFound = true
        }
         else {
            const tokenIds = await newState.contract.methods.tokensOfOwner(newState.account).call()
            if (tokenIds.length == 0) {
              newSecretFound = true
            }

            for (var i = 1; i <= tokenIds.length; i++) {

              const secret = await newState.contract.methods.secrets(tokenIds[i-1]-1).call()
              const myUri = await newState.contract.methods.tokenURI(tokenIds[i-1]).call()

              if (result.localeCompare(secret) !==0) {
                 newSecretFound = true
              }
            }
        }
    }
    return result;
  }

  if (secret.localeCompare("") == 0) { 
    window.alert('Secret cannot be empty!')
    window.location.reload(false);
    return;
  }

// changed conditional to reflect alert...
  if (secret.length > 300) {
  // if (secret.length > 140) {

    window.alert('Secret should not exceed 300 caracters!')
    window.location.reload(false);
    return;
  }

  // console.log('before saving secret imageUrl', imageUrl)
  //console.log('saving secret', iterSecrets.toString())
  const configaxios = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
  const signupUrl = baseWebServiceUrl + '/webservice-php-json/signup.php'
  axios.post(signupUrl, formUrlEncoded({
    secret: secret,
    origin: userAccount,
    image_url: imageUrl,
    date_of_creation:now(),
    approved:0

  }),configaxios)
  .then((response) => {
   // console.log(response);
  }, (error) => {
  //  console.log(error);
  });

  randomSecret =  await loadSecret()

  showResults = true;
  showShareMySecretComponent = true;

  let val= {
    randomSecret : randomSecret,
    showResults : showResults,
    showShareMySecretComponent : showShareMySecretComponent,
    randomSecretId : randomSecretId,
  }
  extraFunc(val)
}

handleStateChange = (val) => {
  this.setState({
    randomSecret : val.randomSecret,
    showResults : val.showResults,
    showShareMySecretComponent : val.showShareMySecretComponent,
    randomSecretId : val.randomSecretId,
  })
}
handleImageUrl = (val) => {
  this.setState({
    imageUrl : val
  })
}

  // NO LONGER SHOWING MESSAGE
  // displayMessage = () => {
  //
  //  this.setState({showAlert: true})
  // }

///////////////////////////////////////////////////////////////////////////////////////////
// Description:  Not called and not active.
//                Just a place holder for future use.
//                burn is not implemented in the smart contract so no risk at all here
///////////////////////////////////////////////////////////////////////////////////////////

  burn = (secret) => {
    this.state.contract.methods.burn(secret).send({ from: this.state.account })
    .once('receipt', (receipt) => {
      this.setState({
        secrets: [...this.state.secrets, secret]
      })
    })
  }


resetForm = () => {
  this.setState({
    showResults: false
  })
}

///////////////////////////////////////////////////////////////////////////////////////////
// Description:  constructor initializing the state parameters
///////////////////////////////////////////////////////////////////////////////////////////
  constructor(props) {
    super(props)
    this.state = {
      account: '',
      contract: null,
      totalSupply: 0,
      myRedirect: false,
      showAlert: false,
      showResults: false,
      secrets: [],
      imageUrl: ""
    }
  }

///////////////////////////////////////////////////////////////////////////////////////////
// Description:  rendering of the application
///////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="app-div">
        <Router>
          <Navbar userAccount={userAccount} />
          { this.state.showResults ?  <Redirect push to='/secret' /> : null}
          <Route path="/" exact>
            <Home
              secret={this.secret}
              saveSecret={this.saveSecret}
              handleImageUrl={this.handleImageUrl}
              state={this.state}
              handleStateChange={this.handleStateChange}
            />
          </Route>
          <Route path="/secret">
            <ViewSecret
              mint={this.mint}
              imageUrl={this.state.imageUrl}
              state={this.state}
              userAccount={userAccount}
              resetForm={this.resetForm}
            />
          </Route>
          <Route path="/mint-a-secret">
            <MintSecret
              userAccount={userAccount}
            />
          </Route>
          <Route path="/holdings">
            <Holdings
              secrets={this.state.secrets}
              yourSecrets={this.state.secrets}
              pendingSecrets={[]}
              mapAllImages={mapAllImages}
              mapTokenImages={mapTokenImages}
            />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Router>
        <Alert
          open={this.state.showAlert}
          closeModal={() => this.setState({showAlert: false})}
          title={"It can take a few minutes to mint a secret"}
          text={"In the meantime, check out your Holdings. If you're having trouble, make sure MetaMask is properly configured and check out Minting a Secret"}
          linkText={"Holdings Page"}
          linkAddress={"/holdings"}
          closeMessage={"Okay!"}
        />
      </div>
    );
  }
}

export default App;

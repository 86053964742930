import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

class ViewSecret extends Component {

///////////////////////////////////////////////////////////////////////////////////////////
// Render View Secret
///////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount = (prevProps, prevState) => {
    this.props.resetForm()
  }
  render() {
    let imageUrl = this.props.imageUrl;

    if (imageUrl && imageUrl.length > 0) {
      const sliceIndex = imageUrl.indexOf("/images")
      if (sliceIndex && sliceIndex > 0) {
        imageUrl = imageUrl.slice(sliceIndex);
      }
    }

    return (

      <div className="main-container">
        <div className="view-secret">
          { this.props.state.randomSecret ?
            <div className="show-results">
              <h1>
                A stranger shared this with you:
              </h1>
              <div className="secret-container">
                <img src={imageUrl}  width="300" height="300" />
                <h3 className="secret-text" >
                  {this.props.state.randomSecret}
                </h3>
              </div>
              <form
                className={this.props.userAccount && this.props.userAccount.length > 0 ? "mint-form" : "mint-form no-account"}
                onSubmit={(event) => {
                  event.preventDefault()
                  this.props.mint(this.props.state.randomSecret,this.props.state.randomSecretId)
                }}>
                <input
                  type='submit'
                  className='btn purple'
                  value='Mint this secret'
                  disabled={this.props.userAccount && this.props.userAccount.length > 0 ? false : true}
                />
                <div className="hidden-message">
                  You don't have MetaMask connected — please check out <Link to="/mint-a-secret">this page</Link>!
                </div>

              </form>

            </div>
          :
          <div>
            {this.props.history.push('/')}
          </div>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(ViewSecret);

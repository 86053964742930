import React, { Component } from 'react';

class Home extends Component {

///////////////////////////////////////////////////////////////////////////////////////////
// Render HOME
///////////////////////////////////////////////////////////////////////////////////////////
  // state = {
  //   disabled: false,
  // }
  // componentDidMount() {
  //   this.setState({disabled: false})
  // }
  // componentDidUpdate() {
  //   if (this.state.disabled !== false) this.setState({disabled: false})
  // }

  render() {
    return (

      <div className="main-container">
        <div className="add-secrets">
          <h1>
            Share your secret and get someone else's
          </h1>
          <form
            className="share-form"
            onSubmit={(event) => {
              event.preventDefault()
              const secret = this.secret.value
              this.props.saveSecret(secret, this.props.handleStateChange, this.props.state, this.props.handleImageUrl)
            }}>
            <input
              type='text'
              className='secret-input'
              placeholder='e.g. I can transform gold into bitcoin'
              ref={(input) => { this.secret = input }}
            />
            <input
              type='submit'
              className='btn purple'
              value='Share my secret'
              // disabled={this.state.disabled}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default Home;

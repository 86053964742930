import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Holdings extends Component {

///////////////////////////////////////////////////////////////////////////////////////////
// Render HOLDINGS
///////////////////////////////////////////////////////////////////////////////////////////

  renderSecret = (secret, i) => {
    // console.log(this.props.mapAllImages, this.props.mapTokenImages.get(secret), secret, this.props.mapTokenImages)
    return (
      <div className="secret-container" key={i} >
        <img src={`${this.props.mapAllImages.get(this.props.mapTokenImages.get(secret))}`} alt={secret} width="300" height="300"/>
        <h3 className="secret-text" >
          {secret}
        </h3>
      </div>
    )
  }

  render() {
    return (

      <div className="main-container">
        <div className="holdings">
          { this.props.secrets && this.props.secrets.length > 0 ?
            <div className="holdings-secrets">
              {this.props.pendingSecrets && this.props.pendingSecrets.length > 0 ?
                <div className="pending-secrets-list">
                  <h5>
                    Here are your pending secrets:
                  </h5>
                  {this.props.pendingSecrets.map(this.renderSecret)}
                </div>
              : ""}
              {this.props.yourSecrets && this.props.yourSecrets.length > 0 ?
                <>
                  <h5>
                    Here are your secrets:
                  </h5>
                  <div className="active-secrets-list">
                    {this.props.yourSecrets.map(this.renderSecret)}
                  </div>
                </>
              : ""}
            </div>
          :
          <div className="message">
            <h4>
              Hey, it looks like you haven’t minted any secrets yet!
            </h4>
            <h4>
              Click <Link to="/mint-a-secret">here</Link> to learn how to mint a secret.
            </h4>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default Holdings;

import React from 'react';

function About() {
	return (
		<div className="main-container start">
			<div className="about">
				<h2>
					ABOUT
				</h2>
				<h5>
					What is Secrets Exchange?
				</h5>
				<p>
					Secrets Exchange creates moments of serendipitous connection between strangers who will never meet each other. The project reimagines our understanding of the blockchain by exploring questions of value and ownership, and transforming secrets, which are traditionally sources of shame and burden, into aesthetic and collectible experiences.
				</p>
				<p>
					Secrets Exchange is inspired by whispering galleries, where acoustic resonance allows one party whispering into a wall to be heard by another at a distance. In the same way, Secrets Exchange hopes to cultivate intimacy even while we are apart.
				</p>
				<h5>
					How does Secrets Exchange work?
				</h5>
				<p>
					Secrets Exchange is a blockchain-based art project that connects strangers through their secrets in a one-to-one, anonymous, and secure way. When you submit a secret, you receive a secret someone else has submitted, paired with a collectible gif that corresponds to their secret. Every secret-gif pair you see is unique and you have the option of permanently keeping the pair by minting it as a non-fungible token. Submit more secrets to see more secrets, and collect as many secrets as you like!
				</p>
				<h5>
					What should I know about submitting a secret?
				</h5>
				<p>
					You don't need to create an account or a profile to use Secrets Exchange, and we don't ask for or collect any personal information. Communications between you and Secrets Exchange are end-to-end encrypted using https and your secrets are stored in a secure database.
				</p>
				<p>
					Once you submit a secret however, you won’t be able to change or delete it, and it will be stored and shared indefinitely. Keep in mind that while your secret is anonymous, any identifying information you disclose may allow people who view your secret to determine your identity.
				</p>
				<h5>
					When did Secrets Exchange begin?
				</h5>
				<p>
					Secrets Exchange premiered on December 11, 2020 at the <a href="https://codame.com/events/art-tech-festival-2020-joynt" target="_blank" rel="noopener noreferrer">Codame ART+TECH Festival</a>. The project will run until everyone in the world has shared their secret 🤐
				</p>
				<h5>
					How can I get in touch with you?
				</h5>
				<p>
					Feel free to send your comments, questions, and feedback to <a href="mailto: hi@secrets.exchange">hi@secrets.exchange</a> — we'll get back to you as soon as we can!
				</p>
			</div>
		</div>
  );
}

export default About;

import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

function MintSecret(props) {
  let urlEtherscan = ""
  if (props.userAccount && props.userAccount.length > 0) {
    urlEtherscan = 'https://etherscan.io/address/' + props.userAccount
  } else {
    urlEtherscan = "https://etherscan.io/address/" + process.env.REACT_APP_CONTRACT
  }
	return (
		<div className="main-container start">
			<div className="mint-secret">
				<h2 >
					Minting A Secret
				</h2>
				<p>
					When you get a secret-gif pair you like, you can keep it forever by minting it. Each secret-gif pair is unique, and once you mint it, you own both the secret and the gif it’s paired with.You can view your minted secrets in the <Link to="/holdings">Holdings</Link> page.
				</p>
				<p>
					Here’s how to mint a secret:
				</p>
				<p>
					<HashLink
						to="/mint-a-secret#blockchain-basics"
						scroll={(el) => scrollWidthOffset(el)}
     >
						If you’re new to the blockchain, you might want to read Blockchain Basics below first!
					</HashLink>
				</p>
				<h6>
					1. Download the MetaMask browser extension if you don’t have it already.
				</h6>
				<p>
					Go to <a href="https://www.metamask.io" target="_blank" rel="noopener noreferrer">MetaMask.io</a> and install MetaMask for Chrome. Once you have MetaMask installed, it will issue you an Ethereum wallet address which serves as your login, and way to send and receive ETH. Make sure you use a strong password, and also keep multiple copies of your seed phrase on paper and store them in a safe place. If you lose your seed phrase you may not be able to access your Ether!
				</p>
				<h6>
					2. Deposit ETH into your MetaMask wallet.
				</h6>
				<p>
					To transfer ETH into your MetaMask wallet, you can either send funds from an exchange, such as Coinbase, to your MetaMask public address, or buy directly on MetaMask using a debit card, though there is a higher fee to do so.
				</p>
				<p>Plan to have around $10 USD in your MetaMask wallet to account for gas fees.</p>
				<h6>
					3. Refresh your page.
				</h6>
        <p>If your MetaMask extension is configured correctly, a popup should appear.</p>
				<h6>
					4. Submit a secret, and when you see a secret you like, click “Mint Your Secret”.
				</h6>
				<p>
					This will launch MetaMask. Note, pay attention to the “gas fee”! Depending on how busy the network is, the default price can be a bit high. You can change the fee to give your transaction a higher or lower priority by clicking the “Edit” button next to the gas fee when MetaMask pops up. Then go to the “Advanced” tab, you can find a price that you think is reasonable, about 20 GWEI (~$5).
				</p>
				<p>
					Minting records the transaction on the blockchain. You can monitor the transaction status <a href={urlEtherscan} target="_blank" rel="noopener noreferrer">here</a>. Hang tight while minting, it usually takes 5-10 minutes depending on how busy Ethereum is.
				</p>
				<h6 className="last-item">
					5. View your secret and gif on the <Link to="/holdings">Holdings page</Link>!
				</h6>
				<div id="blockchain-basics"/>
				<h2>
					Blockchain Basics
				</h2>

				<details>
          <summary>
            What is the blockchain?
          </summary>
          <p>
            The blockchain is a decentralized system that records transactions between two parties. The data stored is permanently recorded, viewable by anyone, and cannot be altered.
          </p>
        </details>

        <details>
          <summary>
            What is Ethereum and what is Ether?
          </summary>
          <p>
            Ethereum is a blockchain platform that supports the development and operation of decentralized applications. Its native currency, Ether (ETH), functions as both a cryptocurrency (like Bitcoin) and also as payment for the transaction fees of applications that run on the Ethereum network.
          </p>
        </details>
        <details>
          <summary>
            What is Etherscan?
          </summary>
          <p>
            Etherscan tracks the status of all transactions on the Ethereum network. You can track the status of your own address by clicking Etherscan on the menu bar.
          </p>
        </details>
        <details>
          <summary>
            What is an Ethereum wallet?
          </summary>
          <p>
            An Ethereum wallet is a way to manage your Ethereum account, allowing you to send and receive ETH easily. The wallet also serves as a login to connect to many decentralized applications.
          </p>
        </details>
        <details>
          <summary>
            How do I buy Ether (ETH)?
          </summary>
          <p>
            You can buy ETH on a cryptocurrency exchange like Coinbase. If you don't have a cryptocurrency exchange account, the easiest way to buy Ether is with a debit card on MetaMask.
          </p>
        </details>
        <details>
          <summary>
            How much ETH do I need?
          </summary>
          <p>
            Secrets Exchange is free to use. You only need enough ETH to pay for transaction fees (gas) if you decide to mint your secret. Gas fees vary depending on how busy the network is; it’s reasonable to keep around $10 USD in your MetaMask to account for gas.
          </p>
        </details>
        <details>
          <summary>
            What are tokens?
          </summary>
          <p>A token is any digital asset whose ownership is recorded on the blockchain.</p>
        </details>
        <details>
          <summary>
            What is a non-fungible token?
          </summary>
          <p>The most common and recognizable token is the fungible token, Bitcoin. There can be up to 21 million Bitcoin!</p>
          <p>
            A non-fungible token (NFT), however, is a token that represents something unique, and usually rare and valuable, such as digital art and collectibles. NFTs can also be associated with metadata that displays and represents everything from physical to other digital mediums, text, images, videos, and music. NFTs are significant because they can prove authenticity and provenance, and confer ownership via blockchain record.
          </p>
        </details>
        <details>
          <summary>
            What does it mean to mint a non-fungible token (NFT)?
          </summary>
          <p>
            A secret on Secrets Exchange is a non-fungible token, and minting is the process of creating a NFT that you now own. When you decide to mint a secret on Secrets Exchange, you pay a fee for the blockchain to confer your ownership of the token which contains the secret and gif.
          </p>
        </details>
        <details>
          <summary>
            Is every secret I see unique? If I mint it, will no one else be able to see it?
          </summary>
          <p>
            Every secret you see is paired with a collectible gif and every secret-gif pair you see is unique. If you mint the secret, you will own both the secret and the gif and no one else will be able to mint it.
          </p>
        </details>
        <details>
          <summary>
            Can I mint my own secret?
          </summary>
          <p>
            Yes, but it’s unlikely. Every time you submit a secret, you get a different secret. If you submit a lot of secrets, there’s a chance you might encounter one of your own, and if you do, you can feel free to mint it.
          </p>
        </details>
        <details>
          <summary>
            How do I view my minted secrets?
          </summary>
          <p>
            You can view them on the <Link to="/holdings">Holdings</Link> page.
          </p>
        </details>
        <details>
          <summary>
            What do I do with my minted secrets?
          </summary>
          <p>
            Like any collectible or piece of art, you can view, admire, and share them. You can also sell or auction your minted secrets on other sites.
          </p>
        </details>
        <details>
          <summary>
            Do I have to mint what I receive?
          </summary>
          <p>
            No, you don't have to mint anything at all. Only mint what you like!
          </p>
        </details>
        <details>
          <summary>
            What should I be aware of using my wallet?
          </summary>
          <p>
            Be sure to keep your private key safe. Because Ethereum is a decentralized platform, if you lose access to your wallet, you won’t be able to recover your funds.
          </p>
        </details>
        <details>
          <summary>
            What if I have more questions?
          </summary>
          <p>
            Blockchain infrastructure and decentralized systems are ever evolving and the best way to understand what’s going on is to go down the rabbit hole yourself!
          </p>
        </details>


      </div>
		</div>
	);
}

export default MintSecret;
